import { IItem } from "./IItem"
import {minPrice} from './minPrice'
import {maxPrice} from './maxPrice'
import {getAllPrices} from './getAllPrices'
import {generateUrl} from '../../../helpers/slug/generateUrl'
import {TypeTicket} from '../../../models/TypetTicket'
import {getPricesOrder} from './getPricesOrder'

export const getMainItems = ({operationLines, collaboratorStretches, t, typeShow, publicToken, at}) => {
  
  const items: IItem[] = []

  operationLines.forEach(ol => {
    if (ol.disabled_at) return
    ol.stretches.forEach((stretch, index) => {
      if (stretch.disabled_at || stretch.prices.length === 0) return
      const _minPrice = stretch.prices.reduce(minPrice(stretch.tax), {minPrice: null, passengerCategory: ''})
      const _maxPrice = stretch.prices.reduce(maxPrice(stretch.tax), {maxPrice: null, passengerCategory: ''})
      const allPrices = getAllPrices({prices: stretch.prices, money: stretch.primaryMoney.alphabeticCode, tax: stretch.tax})
      
      const currency = stretch.primaryMoney.alphabeticCode
      
      let priceToShow
      
      switch (typeShow) {
        case typePriceShow.max:
          priceToShow = _maxPrice.maxPrice ? _maxPrice.passengerCategory + ' ' + _maxPrice.maxPrice + ' ' + currency : ''
          break
        
        case typePriceShow.orderLow:
          const temp = getPricesOrder({stretch, typeOrder: typePriceShow.orderLow})
          console.log('stretch: ', stretch)
          console.log('temp: ', temp)
          priceToShow =  temp.passengerCategory + ' ' + temp.priceToShow.toFixed(2) + currency + ' '
          break
        
        default:
          priceToShow = _minPrice.minPrice? t('from') + ' ' + _minPrice.passengerCategory + ' ' + _minPrice.minPrice + ' ' + currency : ''
      }
      
      const price = priceToShow
      
      const destinationName = stretch.dataweb?.destination?.name
      let categoryName
      
      if (Array.isArray(stretch?.dataweb?.category)) {
        categoryName = stretch.dataweb?.category?.reduce((prev, curr, index) => {return prev + `${index > 0? ', ' : ''}` + curr.name}, '')
      } else if (typeof stretch.dataweb?.category === 'object') {
        categoryName = stretch.dataweb?.category.name
      }
      
      const temp: IItem = {
        urlSlug: generateUrl({
          prefix: 'tour',
          destination: destinationName,
          category: null,
          publicToken,
          stretch_id: stretch.id,
          operator_id: stretch.operator_id,
          withSlug: true,
          at,
          type: TypeTicket.Stretch,
          nameExperience: stretch.name
        }),
        // @ts-ignore
        moreOptionsText: t('moreOptions'),
        type: 'stretch',
        stretch_id: stretch.id,
        imgUrl: stretch.dataweb?.image1 ? stretch.dataweb.image1.replace('http://', 'https://') : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg',
        title: stretch.name,
        price,
        priceNumeric: _minPrice.minPrice,
        priceCurrency: currency,
        allPrices,
        description: stretch.description,
        destinationName,
        categoryName,
        destination: stretch.dataweb?.destination || null,
        category: stretch.dataweb?.category || null,
        tag: stretch.dataweb?.tag || null,
        tagStr: stretch.dataweb?.tag?.reduce((prev, curr, index) => {return prev + `${index > 0? ', ' : ''}` + curr.name}, ''),
        // @ts-ignore
        ...stretch.recomended? {recomended: true} : undefined
        // ...(index === 0 || index=== 3 || index=== 5 || index=== 8) ? {recomended: true} : undefined
      }
      items.push(temp)
    })
  })

  collaboratorStretches.forEach(cs => {
    if (cs.disabled_at || cs.prices?.length === 0) return

    const _minPrice = cs.prices.reduce(minPrice(cs.tax), {minPrice: 0, passengerCategory: ''})
    const _maxPrice = cs.prices.reduce(maxPrice(cs.tax), {maxPrice: 0, passengerCategory: ''})
    const allPrices = getAllPrices({prices: cs.prices, money: cs.primaryMoney.alphabeticCode, tax: cs.tax})

    const currency = cs.primaryMoney.alphabeticCode

    let priceToShow
    
    switch (typeShow) {
      case typePriceShow.max:
        priceToShow = _maxPrice.maxPrice ? _maxPrice.passengerCategory + ' ' + _maxPrice.maxPrice + ' ' + currency : ''
        break

      case typePriceShow.orderLow:
        const temp = getPricesOrder({stretch: cs, typeOrder: typePriceShow.orderLow})
        priceToShow =temp.passengerCategory + ' ' +  temp.priceToShow.toFixed(2) + currency
        break

      default:
        priceToShow = _minPrice.minPrice? t('from') + ' ' + _minPrice.passengerCategory + ' ' + _minPrice.minPrice + ' ' + currency : ''
    }
    
    const price = priceToShow
    
    const destinationName = cs.dataweb?.destination?.name
    let categoryName

    if (Array.isArray(cs?.dataweb?.category)) {
      categoryName = cs.dataweb?.category?.reduce((prev, curr, index) => {return prev + `${index > 0? ', ' : ''}` + curr.name}, '')
    } else if (typeof cs.dataweb?.category === 'object') {
      categoryName = cs.dataweb?.category.name
    }
    
    const temp: IItem = {
      urlSlug: generateUrl({
        prefix: 'tour',
        destination: destinationName,
        category: null,
        publicToken,
        stretch_id: cs.id,
        operator_id: cs.operator_id,
        withSlug: true,
        at,
        type: TypeTicket.Stretch,
        nameExperience: cs.name
      }),
      moreOptionsText: t('moreOptions'),
      type: 'cs',
      stretch_id: cs.id,
      operator_id: cs.operator_id,
      imgUrl: cs.dataweb.image1 ? cs.dataweb.image1.replace('http://', 'https://') : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg',
      title: cs.name,
      price,
      priceNumeric: _minPrice.minPrice,
      priceCurrency: currency,
      allPrices,
      description: cs.description,
      destinationName: cs.dataweb?.destination?.name,
      destination: cs.dataweb?.destination || null,
      category: cs.dataweb?.category || null,
      categoryName: cs.dataweb?.categor?.name,
      tag: cs.dataweb?.tag || '',
      tagStr: cs.dataweb?.tag?.reduce((prev, curr) => {return prev + ',' + curr.name}, ''),
    }
    items.push(temp)
  })
  return items
}

export const typePriceShow = {
  max: 'max',
  min: 'min',
  orderLow: 'order_low',
  orderHigh: 'order_high'
}